.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.aaron {
  appearance: none;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgb(255, 255, 255);
  background-image: none;
  background-origin: padding-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  border-bottom-color: rgba(34, 36, 38, 0.15);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(34, 36, 38, 0.15);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(34, 36, 38, 0.15);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgba(34, 36, 38, 0.15);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: block;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 400;
  height: 38px;
  letter-spacing: normal;
  line-height: 17px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  max-width: 100%;
  outline-color: rgba(0, 0, 0, 0.87);
  outline-style: none;
  outline-width: 0px;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 9.5px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 9.5px;
  text-align: left;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  transition-delay: 0s, 0s;
  transition-duration: 0.1s, 0.1s;
  transition-property: color, border-color;
  transition-timing-function: ease, ease;
  user-select: text;
  vertical-align: top;
  visibility: visible;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
  -webkit-font-smoothing: antialiased;
  -webkit-rtl-ordering: logical;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-border-image: none;
}