.test {
  font-size: 40px;
  color: #FFF;
}

button {
  color: blue;
}
.actionButton {
  font-size: 30px;
  color: #08c;
}

#mainDashboard {
  height: -webkit-calc(100% - 100px);
  height:    -moz-calc(100% - 100px);
  height:         calc(100% - 100px);
}
