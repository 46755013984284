.blurring.dimmed.dimmable > :not(.dimmer) {
    filter: none;
}

body > div:nth-child(11) {
  z-index: 9999;
}

body > div:nth-child(10) {
  z-index: 9999;
}

label {
  color: white;
}

.ui.form .field>label {
  color: white !important;
}

body > div.ui.page.modals.dimmer.transition.visible.active > div > div.content > form > div > div.required.sixteen.wide.field > label {
  color: white !important;
}