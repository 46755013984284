.test {
  font-size: 40px;
  color: #FFF;
}

button {
  color: blue;
}
.actionButton {
  font-size: 30px;
  color: #08c;
}

#mainDashboard {
  height: -webkit-calc(100% - 100px);
  height:    -moz-calc(100% - 100px);
  height:         calc(100% - 100px);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
  background: #1B1C1D;
  color: rgba(255, 255, 000, 0.9);
}