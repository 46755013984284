.watercoolerNav {
  -webkit-box-shadow: 1px 1px 10px #A8B5BF;
  -moz-box-shadow:    1px 1px 10px #A8B5BF;
  box-shadow:         1px 1px 10px #A8B5BF;
  z-index:999;
}

.avatar {
  -webkit-box-shadow: 1px 1px 2px #5D6F80;
  -moz-box-shadow:    1px 1px 2px #5D6F80;
  box-shadow:         1px 1px 2px #5D6F80;
  z-index:9999;
  position: fixed;
  right: 12px;
  top: 12px;
}

.footer {
  box-shadow: rgb(204, 219, 232) 1px 1px 1px 0px inset, rgba(255, 255, 255, 0.5) -1px -1px 1px 1px inset;
}

.popup {
  z-index: 9999;
}